import React, { Component } from "react";
import "./useful-links.css";
class LinksPage extends Component {
  state = {
    pages: [
      {
        name: "Sci-Hub",
        details: "Get any research you want without a subscription",
        url: "https://scihub.wikicn.top/",
      },
      {
        name: "Figma",
        details: "Creating mockups",
        url: "https://www.figma.com/",
      },
      {
        name: "Mixamo",
        details:
          "Get 3D animated characters with skeletons for your games for free",
        url: "https://www.mixamo.com/",
      },
      {
        name: "Quixel",
        details: "Amazing realistic mesh items by the team @ Quixel",
        url: "https://quixel.com/",
      },
      {
        name: "Picsum",
        details: "Get any size random photo for your mock up",
        url: "https://picsum.photos/",
      },
      {
        name: "Blueprints links for UE4",
        details: "Share your Unreal Engine blueprints with people",
        url: "https://www.blueprintue.com/",
      },
      {
        name: "Online Photoshop",
        details: "Just Photoshop.",
        url: "https://www.photopea.com/",
      },
      {
        name: "NativeScript Image Builder",
        details:
          "Convert your images to fit all sizes for your Android/iOS projects.",
        url: "http://nsimage.brosteins.com/",
      },
      {
        name: "StatQuest",
        details:
          "Your best Machine Learning teacher (Double BAM!.... you will understand)",
        url: "https://statquest.org/",
      },
      {
        name: "Metal Concerts Worldwide",
        details:
          "If you are travelling, make sure to check for metal concerts nearby",
        url: "https://en.concerts-metal.com/prochains-concerts-metal.php",
      },
      {
        name: "Passport Index",
        details:
          "Find out travel restrictions to any country with your passport",
        url: "https://www.passportindex.org/",
      },
      {
        name: "Cron Guru",
        details: "Schedule cron jobs.",
        url: "https://crontab.guru/",
      },

      {
        name: "This person does not exist",
        details: "An AI which generates photos of people that never existed",
        url: "https://thispersondoesnotexist.com/",
      },
      {
        name: "Blueprint/C++ Comparison for UE4",
        details:
          "30+ Free Comparisons Between Blueprint and C++ in Unreal Engine",
        url: "https://gum.co/kurET",
      },
      {
        name: "TinyWow",
        details: "Do anything possible online",
        url: "https://www.tinywow.com",
      },
      {
        name: "Axiom AI",
        details: "Create bots to automate anything",
        url: "https://www.axiom.ai",
      },
      {
        name: "Open-source alternative",
        details: "Find open source alternatives",
        url: "https://www.opensourcealternative.to/",
      },
      {
        name: "Aperi'Solve",
        details:
          "Aperi'Solve is an online platform which performs layer analysis on image",
        url: "https://www.aperisolve.com/",
      },
      {
        name: "metatags.io",
        details: "Preview, edit, and generate metatags for your website",
        url: "https://metatags.io/",
      },
    ],
  };
  getLinks = () => {
    return this.state.pages.map((page, index) => (
      <tr key={page.url}>
        <th scope="row">{index + 1}</th>
        <td>
          <a href={page.url} target="_blank" rel="noopener noreferrer">
            {page.name}
          </a>
        </td>
        <td>{page.details}</td>
      </tr>
    ));
  };
  render() {
    return (
      <div className="link-container">
        <div className="table table-dark table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Details</th>
              {/* <th scope="col">Link</th> */}
            </tr>
          </thead>

          <tbody>{this.getLinks()}</tbody>
        </div>
      </div>
    );
  }
}

export default LinksPage;
