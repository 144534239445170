import React, { Component } from "react";
import "./cv.css";

// const embeddedUrl =
//   "https://docs.google.com/document/d/e/2PACX-1vQv_uzD43BczovQ2VSWeclSR_fnjn5TvHfBBZ7JjFzU_GYKedYShoXgBHWYxfEE4XUGtzPRTnFnJoyr/pub?embedded=true";
// const url =
//   "https://docs.google.com/document/d/11jAHd7B1xFnazBgIUukr8ON3VI2HohBCadJ9rH8V_ko";
const url =
  "https://docs.google.com/document/d/1fbkCpdnHyafCVoF2Nn-nSnAy-qkv1empOT_2HxAZ41M";
const embeddedUrl =
  "https://docs.google.com/document/d/e/2PACX-1vQqTodcjPBysqq9NxgZTA4vO-MQAG4STkMSGWFqXtmWx8m15EsHzWhtl8Nm0xon--RGXME8drDvJl1N/pub?embedded=true";
class CV extends Component {
  state = {};
  render() {
    return (
      <div className="cvPage">
        {/* <button
          type="button"
          className="btn btn-warning"
          style={{ width: 200, margin: 20 }}
        >
          <a href={`${url}/export?format=docx`}>Download docx file</a>
        </button> */}
        <button
          type="button"
          className="btn btn-warning"
          style={{ width: 200, margin: 20 }}
        >
          <a href={`${url}/export?format=pdf`}>Download pdf</a>
        </button>
        <div className="embed-responsive embed-responsive-1by1">
          <iframe
            className="embed-responsive-item"
            src={embeddedUrl}
            title="My CV"
            samesite="Strict"
          />
        </div>
      </div>
    );
  }
}

export default CV;
