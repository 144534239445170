import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import MainSidebar from "./components/sidebar/sidebar.jsx";
import Credits from "./components/bottom-credits";
function App() {
  return (
    <div className="Main">
      <div className="content-wrap">
        <Router>
          <MainSidebar />
        </Router>
      </div>
      <Credits />
    </div>
  );
}

export default App;
