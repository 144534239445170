import React, { Component } from "react";
import "./annalist.css";
class AnnaList extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="embed-responsive embed-responsive-4by3">
          <iframe
            className="embed-responsive-item"
            src="https://docs.google.com/document/d/e/2PACX-1vT-7XcR5DxtP8SJf2VPokDNJ3nogSaZ_SrCM2G5ivT6yUUWcvyO7iM5NmJ0V0GfLWdetAE99TcbJvEm/pub?embedded=true"
            title="My CV"
            samesite="Strict"
          />
        </div>
      </div>
    );
  }
}

export default AnnaList;
