import React, { Component } from "react";
import classes from "./calculators.module.css";
import { Header } from "semantic-ui-react";
class Calculators extends Component {
  state = {};
  render() {
    return (
      <div className={classes.Calculators}>
        <Header className="brown">
          <h1 className="display-3">Calculators</h1>
        </Header>
      </div>
    );
  }
}

export default Calculators;
