import React, { Component } from "react";
import "./credits.css";
class Credits extends Component {
  render() {
    return (
      <footer>
        <div className="credits">
          <code>
            All rights reserved to Eden Dupont {new Date().getFullYear()}
          </code>
        </div>
      </footer>
    );
  }
}

export default Credits;
