import React, { Component } from "react";
import classes from "./video-background.module.css";
class VideoBG extends Component {
  state = { video: this.props.video };
  render() {
    return (
      <div className={classes.Container}>
        <video autoPlay="autoplay" loop="loop" muted className={classes.Video}>
          <source src={this.state.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className={classes.Content}>
          <div className={classes.SubContent}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default VideoBG;
