import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import ReactPlayer from "react-player";
import "./youtube-downloader.css";
var YouTube = require("youtube-id");
var unirest = require("unirest");
class YouTubeDownloader extends Component {
  state = {
    input: "",
    output_mp3: "",
    output_mp4: "",
    image_url: "",
  };

  componentDidMount = () => {
    /**
     *
     * This section only works if using HTTPS or localhost
     */
    navigator.clipboard.readText().then((input) => {
      if (YouTubeGetID(input)) {
        this.setState({ input });
      }
    });
  };
  isInputValid = () => {
    if (YouTubeGetID(this.state.input)) {
      return true;
    }
    return false;
  };
  handleInputChange = (event) => {
    const input = event.target.value;
    console.log(input);
    this.setState({ input });
  };

  getLink = () => {
    const link = this.state.input;
    const youtube_id = YouTubeGetID(link);
    if (!youtube_id) {
      return;
    }
    var req_mp3 = unirest(
      "GET",
      `https://free-mp3-mp4-youtube.p.rapidapi.com/${youtube_id}/MP3/spinner/2196f3/100/box-button/2196f3/tiny-button/Download/FFFFFF/yes/FFFFFF/none`
    );
    var req_mp4 = unirest(
      "GET",
      `https://free-mp3-mp4-youtube.p.rapidapi.com/${youtube_id}/MP4/spinner/2196f3/100/box-button/2196f3/tiny-button/Download/FFFFFF/yes/FFFFFF/none`
    );
    const header = {
      "x-rapidapi-host": "free-mp3-mp4-youtube.p.rapidapi.com",
      "x-rapidapi-key": "2f96101e6dmsh05d32eeb0e874b2p1c8654jsnae36de16f8a8",
      useQueryString: true,
    };
    req_mp3.headers(header);
    req_mp4.headers(header);
    req_mp3.end((res) => {
      if (res.error) throw new Error(res.error);
      console.log(res.body);
      this.setState({
        output_mp3: res.body.url,
        image_url: res.body.thumbnail,
      });
    });
    req_mp4.end((res) => {
      if (res.error) throw new Error(res.error);
      console.log(res.body);
      this.setState({ output_mp4: res.body.url });
    });
  };
  render() {
    const style = {
      margin: 15,
      color: "black",
    };
    const mp3 =
      this.state.output_mp3 === "" ? (
        ""
      ) : (
        <button type="button" className="btn btn-info" style={style}>
          <Icon name="youtube" />
          <a
            href={this.state.output_mp3}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download MP3
          </a>
        </button>
      );

    const mp4 =
      this.state.output_mp4 === "" ? (
        ""
      ) : (
        <button type="button" className="btn btn-info" style={style}>
          <Icon name="youtube" />
          <a
            href={this.state.output_mp4}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download MP4
          </a>
        </button>
      );

    const downloadButton = this.isInputValid(this.state.youtube_id) ? (
      <button type="button" className="btn btn-primary" onClick={this.getLink}>
        Get download links
      </button>
    ) : (
      <div>
        <p>
          <Icon name="x" color="red" /> Enter a valid YouTube link
        </p>
      </div>
    );
    const img =
      this.state.image_url === "" ? (
        ""
      ) : (
        <img src={this.state.image_url} alt="Video thumbnail" />
      );
    return (
      <div className="Container youtube">
        <div>
          <h1 className="display-5" style={{ color: "white" }}>
            <Icon name="youtube" color="red" size="large" />
            YouTube Downloader
          </h1>
        </div>
        <form className="w-100 p-3">
          <div className="form-group">
            <input
              type="text"
              value={this.state.input}
              className="form-control"
              placeholder="Paste a YouTube link"
              onChange={this.handleInputChange}
            />
            <strong id="emailHelp" className="form-text text-muted">
              for example: https://www.youtube.com/watch?v=9sicd9NgmYA
            </strong>
          </div>

          {downloadButton}
          <div className=".Container">
            {mp3}
            {mp4}
          </div>
        </form>
        {img}
        <ReactPlayer
          url={this.state.input}
          width="600px"
          height="400px"
          controls={true}
          playsinline={true}
        />
        <p>
          API provided by
          https://rapidapi.com/convertisseur.mp3.video/api/free-mp3-mp4-youtube
        </p>
      </div>
    );
  }
}

export default YouTubeDownloader;

function YouTubeGetID(url) {
  url = YouTube(url);
  console.log(url);
  return url;
}
