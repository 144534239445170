import React, { Component } from "react";
import classes from "./projects.module.css";
import Project from "./project";
class ProjectsPage extends Component {
  state = {
    projects: [
      {
        name: "",
        subname: "",
        logo: "vroscopy_logo.png",
        link: "https://drive.google.com/drive/folders/1Ef9YItyc3ru_lE-05EuThanfyyyEWMRn",
        links: [
          { title: "GitHub", url: "https://github.com/eg7eg7/VRoscopy" },
          {
            title: "YouTube page",
            url: "https://www.youtube.com/channel/UCR-wgwlPirVqc7PfOnkd1-g",
          },
          {
            title: "Download link",
            url: "https://drive.google.com/drive/folders/1Ef9YItyc3ru_lE-05EuThanfyyyEWMRn",
          },
        ],
        youtube: "https://www.youtube.com/watch?v=-2sHN1sw41c",
        details:
          "VRoscopy is the final project me and my partner Daniil Rolnik developed for our B.Sc. Software Engineering degree in Afeka, Tel Aviv and guided by Amir Yatziv. The goal of the project is to enable radiologists and doctors view CT and MRI scans in an interactive way, by turning a series of 2D images into a 3D mesh, and manipulating it with your virtual reality hands to further explore anomalies in the scan. We did this by converting the DICOM file which is the scan to an STL file using the Invesalius3 open-source project written in python, the STL file was converted into an FBX file using the python for blender env, and then integrated into the Unreal Engine 4.23 environment using C++. Our project was developed for the Oculus Rift S/Oculus Quest",
      },
      {
        name: "Lost Boy",
        subname: "Development for virtual reality course",
        logo: "",
        link: "",
        links: [
          {
            title: "Download link",
            url: "https://drive.google.com/file/d/19O9Tx8trsT-tEKZzL5unwCY3MP6yC6N1/view",
          },
        ],
        youtube: "https://www.youtube.com/watch?v=qdj04QcASEo",
        details:
          "I made this project as part of a virtual reality development in Unreal Engine course, where we had to create a game with a total of three missions using what we had learned in the course. I would like to thank my friends Tamir Ohana and Robi Eisenstein for their voice-overs.",
      },
      {
        name: "Song Analyzer",
        subname: "Big Data analysis course",
        logo: "",
        link: "https://docs.google.com/presentation/d/1ffbhkcXsYypXvTuTAwOGewF0fkmfy5P1uay_X6Ur13E/edit?usp=sharing",
        links: [
          {
            title: "Slides presentation",
            url: "https://docs.google.com/presentation/d/1ffbhkcXsYypXvTuTAwOGewF0fkmfy5P1uay_X6Ur13E/edit?usp=sharing",
          },
          {
            title: "Submission file",
            url: "https://drive.google.com/file/d/1uCtoWYPblptG_4ef33swms9uHoPbwyrS/view?usp=sharing",
          },
        ],
        youtube: "https://www.youtube.com/watch?v=8KSoSkRzCaY&feature=youtu.be",
        details:
          "We made this project for a big data course we had taken in college, we took a collection of songs and their lyrics, analyzed them using NRC (emotion lexicon for words) to detect which emotions the song projects using spark and kafka, and then sent them to a MongoDB database. We were then able to view the data on an angular based client.  ",
      },
      {
        name: "CineTeam - Team Coordinator for Cinemas",
        subname: "Android Development course",
        logo: "", //cineteam_logo.webp
        link: "https://play.google.com/store/apps/details?id=com.cinema.cineteam",
        links: [
          {
            title: "Google Play Download",
            url: "https://play.google.com/store/apps/details?id=com.cinema.cineteam",
          },
          {
            title: "Git Repository",
            url: "https://github.com/eg7eg7/cineteam-android-final-project",
          },
          {
            title: "Presentation",
            url: "https://docs.google.com/presentation/d/1nXn00VOlXHFwxZsrFOoYspGTbtbgZPJ42MbAdVOGeaM/edit?usp=sharing",
          },
          {
            title: "Demo",
            url: "https://youtu.be/bMiOi4zIZ24",
          },
        ],
        youtube: "https://youtu.be/bMiOi4zIZ24",
        details:
          "We developed this project for Android development course. The app helps cinema workers communicate with each other effectively, allowing them to view a summarized status of the halls, and quick problem reporting which informs all relevant workers automatically. The app was intended to be launched, however COVID-19 hit us the moment we finished, so for now it is dropped.",
      },
      {
        name: "Distributed micro-services",
        subname: "Cloud programming course",
        logo: "",
        link: "",
        links: [
          {
            title: "Git repository Reactive programming",
            url: "https://github.com/eg7eg7/micro-services-project",
          },
          {
            title: "Git repository of course exercises",
            url: "https://github.com/liranzxc/CloudEngine",
          },
        ],
        youtube: "",
        details:
          "In this course we focused on developing an app with distributed microservices, where each service serves for one use-case, and is independent from other services. At the beginning we focused on building the microservices with normal REST API, and we later converted it into a reactive programming architecture using Spring WebFlux.",
      },
      {
        name: "Randy's Hankey",
        subname: "Android Development",
        logo: "",
        link: "https://play.google.com/store/apps/details?id=com.hankey.hw1",
        links: [
          {
            title: "Google Play Download",
            url: "https://play.google.com/store/apps/details?id=com.hankey.hw1",
          },
          {
            title: "Git repository",
            url: "https://github.com/danrol/AndroidHW",
          },
        ],
        youtube: "",
        details:
          "We created a South Park themed game as part of the homework given to us during Android Development course. We were very pleased with the results so we decided to launch it.",
      },
      {
        name: "Perceptron - Machine Learning Algorithm",
        subname: "Parallel and Distributed computation course",
        logo: "",
        link: "",
        links: [
          {
            title: "Documentation",
            url: "https://docs.google.com/document/d/15WCWPiEPKRQuIPz3T09iGdsJC0VyOIke7oehrF0RBjk/edit?usp=sharing",
          },
          {
            title: "Git Repository",
            url: "https://github.com/eg7eg7/Perceptron-Parallel",
          },
        ],
        youtube: "",
        details:
          "This project summarized my parallel computation course. We were given a dataset of points, each point belonging to either blue or red group. The task was to find a line which best divides the two groups (finding the coefficients of a linear polynomial with a degree of 1), the points could be multi-dimensional, up to 20 dimensions. We were tasked to implement the Perceptron algorithm to solve the problem, using parallel methods in C libraries to best use all the resources possible. The implementation included CUDA9 for graphic card utilization, OpenMP to use all the CPU cores, and MPI for distributed computing, utilizing other computers for the job.",
      },
      {
        name: "Docker Presentation",
        subname: "Cloud Development course",
        logo: "",
        link: "https://docs.google.com/presentation/d/1nEzgiwGqtNboUthyY0G5lj70hokGoFfmORHv56fBgyU/edit#slide=id.p",
        links: [
          {
            title: "Liran Nachman presenting our guide",
            url: "https://www.youtube.com/watch?v=37jlMmZysWk",
          },
          {
            title: "Docker Presentation",
            url: "https://docs.google.com/presentation/d/1ZW41iIfic0A6xSDXvq7FEkSTvIcublIKFGx6nFk1AhQ/edit",
          },
          {
            title: "Docker User Manual for begginers",
            url: "https://docs.google.com/presentation/d/1nEzgiwGqtNboUthyY0G5lj70hokGoFfmORHv56fBgyU/edit#slide=id.p",
          },
        ],
        youtube: "https://www.youtube.com/watch?v=37jlMmZysWk",
        details:
          'In our Cloud Computing course, we were tasked with presenting a technology which is useful for cloud environments. We chose Docker because it helps greatly in deployments, and your app will work anywhere regardless of the system, which is essentially what we need when we create programs for computers that are located in the "cloud".',
      },
      {
        name: "How to secure electronic voting presentation",
        subname: "Seminar in cyber security",
        logo: "",
        link: "",
        links: [
          {
            title: "Full Summary - docx",
            url: "https://drive.google.com/file/d/1fFP6H02hLEZ2VMKctFPBY14GBoXrMd9Q/view?usp=sharing",
          },
          {
            title: "Presentation - ppt",
            url: "https://docs.google.com/presentation/d/1T2_QZ6K2_kPqeg0M7odCQYycr6HgjyhNX0kenlJHQ7k/edit?usp=sharing",
          },
        ],
        youtube: "",
        details:
          "During this seminar, Daniil Rolnik and I did an extensive research on how electronic voting can be secured, we reviewed other forms of voting, we looked into other countries which already use electronic voting or other types of voting, we reviewed to upsides and the downsides of electronic voting, and the impact it could have. We also reviewed algorithms behind existing systems, and demonstrated methods from different research papers. We did a 3 hour presentation in class with all our conclusions (Psst. Spoiler alert - We better stick to papers)",
      },
      {
        name: "Ray Tracing - Presentation",
        subname: "Parallel and Distributed computation course",
        logo: "",
        link: "https://drive.google.com/file/d/1OURTpkrAbgMQQwNEbcaDU_B4kP2lPBMP/view?usp=sharing",
        links: [
          {
            title: "Presentation - ppt",
            url: "https://drive.google.com/file/d/1OURTpkrAbgMQQwNEbcaDU_B4kP2lPBMP/view?usp=sharing",
          },
        ],
        youtube: "",
        details:
          "We did presentation for the Parallel computation course, where we had to demonstrate a real-life application of parallel methods. Ray-tracing, or RTX, as of 2020 is the pinnacle for computer graphics, it allows you to view scenes very realistically because it simulates light rays - just like in life. We reviewed the algorithm of how it works and how it can be paralleled with graphic cards.",
      },
      // {
      //   name: "Around the world in 80 Questions",
      //   subname: "Integrative programming course",
      //   logo: "",
      //   link: "",
      //   links: [
      //     {
      //       title: "Git repository",
      //       url: "https://github.com/danrol/ATW80Q",
      //     },
      //     {
      //       title: "Demo",
      //       url: "https://www.youtube.com/watch?v=McctEC1IF9U",
      //     },
      //     {
      //       title: "Final Presentation",
      //       url: "https://github.com/danrol/ATW80Q/blob/master/Documentation,%20Paperwork,%20Presentation/Project%20ATW80Q%20Presentation.pptx?raw=true",
      //     },
      //   ],
      //   youtube: "https://www.youtube.com/watch?v=McctEC1IF9U",
      //   details:
      //     "In this course, it was the very first time we had the opportunity to truly work as a team in our studies, which was our biggest challenge. Each member of the team had his own role in the project. All teams had to create their own API, with common guidelines of how they interact. The API was created using Java Spring Boot with a maven project, we used MySQL with JPA as our database, had JUnit tests, and we also used Hibernate as a stub db.",
      // },
      {
        name: "Voiced Speech Detection",
        subname: "Speech Recognition course",
        logo: "",
        link: "",
        links: [
          {
            title: "Project Summary",
            url: "https://github.com/danrol/SpeechRecognitionFinalProject/blob/master/%D7%A1%D7%99%D7%9B%D7%95%D7%9D%20%D7%A4%D7%A8%D7%95%D7%99%D7%A7%D7%98%20%D7%A1%D7%99%D7%95%D7%9D.%20%D7%A7%D7%95%D7%A8%D7%A1%20%D7%96%D7%99%D7%94%D7%95%D7%99%20%D7%93%D7%99%D7%91%D7%95%D7%A8.pdf",
          },
          {
            title: "Git Repository",
            url: "https://github.com/danrol/SpeechRecognitionFinalProject",
          },
        ],
        youtube: "",
        details:
          "In this project we were tasked with creating a voice detector for recordings, based on researches using methods we learned in the course. In a voice recording, or more precisely, in human speech, there are sound which require you to use voice, and some that don't ('t', 'k' and 'sh' for example are unvoiced, and 'v','d','a','e' are voiced). Using our MATLAB script we were able to filter out unvoiced sections only to hear the voiced ones. You can view more information in the summary below.",
      },
      {
        name: "LSTM - Long Short Term Memory NN",
        subname: "Neural Networks course",
        logo: "",
        link: "https://github.com/eg7eg7/lstm-neural-network-exercise/blob/master/README_SOLUTION.pdf",
        links: [
          {
            title: "Git repository",
            url: "https://github.com/eg7eg7/lstm-neural-network-exercise",
          },
          {
            title: "Project UI",
            url: "https://github.com/eg7eg7/lstm-neural-network-exercise/blob/master/README_SOLUTION.pdf",
          },
        ],
        youtube: "",
        details:
          "This was one of the exercises for our Neural Networks course. We created a trained Long-short-term-memory neural network with different texts, and inside the GUI, we are able to type a text, and it predicts your next sentences based on your text. The NN was implemened with Keras",
      },
      {
        name: "KNN Classifier for voiced numbers",
        subname: "Machine Learning course",
        logo: "",
        link: "https://github.com/eg7eg7/knn-classifier-machine-learning-exercise-mfcc",
        links: [
          {
            title: "Git repository",
            url: "https://github.com/eg7eg7/knn-classifier-machine-learning-exercise-mfcc",
          },
        ],
        youtube: "",
        details:
          "This was an exercise for a machine learning course, we were given voice recordings of numbers, extracted the MFCC of each recording, we classified each MFCC into a KNN model, and with each new recording I was able to classify which number it was by looking at the K neighboring recordings.",
      },
      {
        name: "Multi-language search engine",
        subname: "Olim in Tech Hackathon - August 2019",
        logo: "",
        link: "",
        links: [
          {
            title: "Git repository",
            url: "https://github.com/liranzxc/OlimHackthon",
          },
          {
            title: "Demo - TBD",
            url: "#",
          },
          {
            title: "Olim in Tech",
            url: "https://www.facebook.com/OlimInTech",
          },
        ],
        youtube: "",
        details:
          "The goal of the hackathon was to help people, specifically new people in the country (Olim) get easy access to local facilities. The current state of search engines is that you only get results which correspond to the language you are searching in. We built a search engine which allows the user to search anything in any language she wants, and get results in different languages from across the web without the language barrier. ",
      },
      {
        name: "Story Time",
        subname: "HCI Course",
        logo: "",
        link: "",
        links: [
          {
            title: "Final Presentation",
            url: "https://drive.google.com/file/d/15ogXaLcu7r0iK2emJp_ayf9ib-q-wdDf/view?usp=sharing",
          },
          {
            title: "Adobe XD Mockup",
            url: "https://drive.google.com/file/d/1A4AGN47hCKZnSQHYvaV6_tKafL-jH-MT/view?usp=sharing",
          },
        ],
        youtube: "",
        details:
          "This is the product of our HCI course - where we learned how to effectively design a UX/UI, with the user in mind. The project aims to make the user less lonely by having someone to talk to, and as a sideffect keep their memories alive for generations to come. We had to design the app for the older generation (65+) - so everything we design should be easy to see (eyesight problems at old age) with big fonts and distinct colors, and also easy to pick up and understand.",
      },
      {
        name: "Whiskers",
        subname: "Show Scheduler for Cinemas",
        logo: "",
        link: "",
        links: [
          {
            title: "Git Repository",
            url: "https://github.com/eg7eg7/Whiskers",
          },
        ],
        youtube: "",
        details:
          "I created this tool during my time working at a Cinema in the projection room. Each week we would get an excel sheet and tediously go over each line to summarize it on a notebook, and compare the next week with the previous one. I've created a tool which reads the excel file and does all of it automatically and saved many work hours.",
      },
      // {
      //   name: "Estate Radar",
      //   subname: "Requirement Engineering course",
      //   logo: "",
      //   link: "https://docs.google.com/presentation/d/1zMcZyerLUxeEH0vNJBiDmlIHxD2WCbGffsTW8mIv8Q8/edit?usp=sharing",
      //   links: [
      //     {
      //       title: "Introduction Video",
      //       url: "https://www.youtube.com/watch?v=zGBrQECnC4A",
      //     },
      //     {
      //       title: "Presentation",
      //       url: "https://docs.google.com/presentation/d/1zMcZyerLUxeEH0vNJBiDmlIHxD2WCbGffsTW8mIv8Q8/edit?usp=sharing",
      //     },
      //   ],
      //   youtube: "https://www.youtube.com/watch?v=zGBrQECnC4A",
      //   details:
      //     "In this course we were supposed to define the requirements of a project, our idea was a real-estate website where people could look for a new home with more ease than the current state. We defined the actors of the system, use cases, functional and non-functional requirements, user flows and alternative user flows. In the below video we made an introduction video for our project.",
      // },
    ],
  };

  getProjects = () => {
    return this.state.projects.map((project) => (
      <Project info={project} key={project.name} />
    ));
  };
  render() {
    return (
      <article className="containers">
        <div className={classes.Content}>
          <div>{this.getProjects()}</div>
        </div>
      </article>
    );
  }
}

export default ProjectsPage;
