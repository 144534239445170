import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Calculators from "./calculators/calculators";
import YouTubeDownloader from "./youtube-downloader/youtube-downloader";
import CV from "./cv/cv";
import AstronautsPage from "./astronauts/astronauts";
import KanyeSays from "./kanye-west/kanye-says";
import Home from "./home";
import ProjectsPage from "./projects/projects";
import LinksPage from "./useful-links/useful-links";
import AnnaList from "./anna-list/annalist";
class MainView extends Component {
  state = {};
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/calculators" exact component={Calculators} />
        <Route path="/youtube_downloader" exact component={YouTubeDownloader} />
        <Route path="/cv" exact component={CV} />
        <Route path="/astronauts" exact component={AstronautsPage} />
        <Route path="/kanye" exact component={KanyeSays} />
        <Route path="/projects" exact component={ProjectsPage} />
        <Route path="/links" exact component={LinksPage} />
        <Route path="/anna" exact component={AnnaList} />
        <Redirect to="/" />
      </Switch>
    );
  }
}

export default MainView;
