import React, { Component } from "react";
import { Container, Icon } from "semantic-ui-react";
import classes from "./home.module.css";
import VideoBG from "./video-background/video-background";
import { Link } from "react-router-dom";
class Home extends Component {
  state = {};
  render() {
    const video = "stars.mp4";
    return (
      <VideoBG video={video} className="home">
        <div className={classes.Intro}>
          <Container textAlign="center">
            <p>Welcome to my portfolio, where innovation meets execution</p>
            <br></br>
            <p>My name is Eden, and I'm a Software Engineer, </p>
            <p>
              You can find some of my works <Link to="projects">here</Link> on
              this site and on{" "}
              <a href="https://github.com/eg7eg7/">
                GitHub <Icon name="github"></Icon>
              </a>
            </p>
            <p>
              you can contact me at{" "}
              <a href="mailto:eg7eg7@gmail.com">
                <Icon name="mail"></Icon>eg7eg7@gmail.com
              </a>
            </p>

            <p>
              Or keep in touch at{" "}
              <a href="https://www.linkedin.com/in/eden-dupont/">
                <Icon name="linkedin" />
                LinkedIn
              </a>
            </p>
          </Container>
        </div>
      </VideoBG>
    );
  }
}

export default Home;
