import React, { Component } from "react";
import ReactPlayer from "react-player";
import "./project.css";
class Project extends Component {
  state = {
    name: this.props.info.name,
    subname: this.props.info.subname,
    logo: this.props.info.logo,
    title_link: this.props.info.link,
    links: this.props.info.links,
    youtube: this.props.info.youtube,
    details: this.props.info.details,
  };

  getLinks = () => {
    return this.state.links.map((link) => (
      <div key={link.title + link.url}>
        <a href={link.url} target="_blank" rel="noopener noreferrer">
          {link.title}
        </a>
      </div>
    ));
  };

  getHeader = () => {
    const head = (
      <h1 className="display-5 text-primary">
        {this.state.name}
        <small>
          &ensp;&ensp;
          {this.state.subname}
        </small>
      </h1>
    );
    const logo = <img src={this.state.logo} alt="Logo" width="30%" />;

    if (this.state.logo !== "") {
      return logo;
    }
    return head;
  };
  getHeaderLink = () => {
    if (this.state.title_link !== "") {
      return (
        <a
          href={this.state.title_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {this.getHeader()}
        </a>
      );
    }
    return this.getHeader();
  };
  render() {
    return (
      <article className="project">
        <div className="title">{this.getHeaderLink()}</div>
        <p>{this.state.details}</p>
        <div className="links">
          {this.state.links.length !== 0 ? <p>Links:</p> : ""}
          {this.getLinks()}
        </div>
        {this.state.youtube !== "" ? (
          <div className="video">
            <ReactPlayer
              className="react-player"
              url={this.state.youtube}
              controls={true}
              // width="100%"
              // height="100%"
              style={{
                height: "10px",
              }}
            />
          </div>
        ) : (
          ""
        )}
      </article>
    );
  }
}

export default Project;
