import React from "react";
import { Checkbox, Icon, Menu, Sidebar } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./sidebar.css";
import MainView from "../mainview";
const MainSidebar = () => {
  const [visible, setVisible] = React.useState(true);

  return (
    <React.Fragment>
      <Checkbox
        className="SidebarToggler"
        checked={visible}
        label={{
          children: (
            <code>
              <kbd>{visible ? "Hide sidebar" : "Show sidebar"}</kbd>
            </code>
          ),
        }}
        onChange={(e, data) => setVisible(data.checked)}
      />

      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          onHide={() => setVisible(false)}
          vertical
          visible={visible}
          width="thin"
        >
          <Link to="/">
            <Menu.Item>
              <Icon name="home" />
              Home
            </Menu.Item>
          </Link>
          {/* <Link to="/calculators">
            <Menu.Item>
              <Icon name="calculator" />
              Calculators
            </Menu.Item>
          </Link> */}

          {/* <Link to="/astronauts">
            <Menu.Item>
              <Icon name="space shuttle" />
              Astronauts in Space
            </Menu.Item>
          </Link> */}
          {/* <Link to="/kanye">
            <Menu.Item>
              <Icon name="smile" />
              Kanye Says
            </Menu.Item>
          </Link> */}
          <Link to="/cv">
            <Menu.Item>
              <Icon name="file alternate" />
              CV
            </Menu.Item>
          </Link>
          <Link to="/projects">
            <Menu.Item>
              <Icon name="connectdevelop" />
              Projects
            </Menu.Item>
          </Link>
          <Link to="/links">
            <Menu.Item>
              <Icon name="code branch" />
              Useful Links
            </Menu.Item>
          </Link>
          {/* <Link to="/youtube_downloader">
            <Menu.Item>
              <Icon name="youtube" />
              YouTube Downloader
            </Menu.Item>
          </Link> */}
        </Sidebar>
        <MainView />
      </Sidebar.Pushable>
    </React.Fragment>
  );
};

export default MainSidebar;
