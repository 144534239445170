import React, { Component } from "react";
import { Header, Icon, List, Image } from "semantic-ui-react";
import VideoBG from "../video-background/video-background";
class AstronautsPage extends Component {
  state = { total: 0, astronauts: [], iss_location: { long: 0, lat: 0 } };
  interval;
  componentDidMount = () => {
    try {
      this.interval = setInterval(async () => {
        this.updateISS();
      }, 2000);
    } catch (e) {
      console.log(e);
    }
    this.updateAstronauts();
    this.updateISS();
  };
  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  getPersonListItem = () => {
    const long = this.state.iss_location.long;
    const lat = this.state.iss_location.lat;
    return this.state.astronauts.map((person) => (
      <List.Item key={person.name}>
        <Image avatar src="spaceman.png" size="tiny" />
        <List.Content>
          <List.Header style={{ color: "brown" }}>{person.name}</List.Header>
          Aircraft:
          {person.craft === "ISS"
            ? ` ${person.craft} - long: ${long} lat: ${lat}`
            : ` ${person.craft}`}
        </List.Content>
      </List.Item>
    ));
  };
  render() {
    return (
      <VideoBG video="stars.mp4">
        <Header as="h2" icon textAlign="center" color="grey">
          <Icon name="space shuttle" circular />
          <Header.Content>
            <p>Total astronauts currently in space</p>
            <h1 className="display-3">
              <p>{this.state.total}</p>
            </h1>
          </Header.Content>
          <List>{this.getPersonListItem()}</List>
        </Header>
      </VideoBG>
    );
  }
  updateISS() {
    fetch("http://api.open-notify.org/iss-now.json")
      .then((result) => result.json())
      .then((value) => {
        console.log(value);
        const position = value.iss_position;
        const iss_location = {
          long: position.longitude,
          lat: position.latitude,
        };
        this.setState({ iss_location });
      });
  }
  updateAstronauts() {
    fetch("http://api.open-notify.org/astros.json")
      .then((result) => result.json())
      .then((value) => {
        console.log(value);
        const total = value.number;
        const astronauts = value.people;
        this.setState({ total, astronauts });
      })
      .catch((err) => console.log(err));
  }
}

export default AstronautsPage;
