import React, { Component } from "react";
import VideoBG from "../video-background/video-background";
import classes from "./kanye-says.module.css";
class KanyeSays extends Component {
  state = {
    sentence: "",
  };
  generateSentence = async () => {
    fetch("https://api.kanye.rest")
      .then((res) => res.json())
      .then((json) => this.setState({ sentence: json.quote }));
  };
  componentDidMount() {
    this.generateSentence();
  }
  render() {
    const video = "earth.mp4";
    /**
     *
     * From here
     * https://www.pexels.com/search/videos/dark%20background/
     */
    return (
      <VideoBG video={video}>
        <div className={classes.Kanye}>
          {" "}
          <strong>
            <h1>Kanye says</h1>
          </strong>
          <p>{this.state.sentence}</p>
          <button
            type="button"
            className="btn btn-outline-dark"
            onClick={this.generateSentence}
          >
            Generate sentence
          </button>
          <img src="kanye.jpg" alt="profile" />
        </div>
      </VideoBG>
    );
  }
}

export default KanyeSays;
